import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {SnackbarType} from '../../enums/snackbar.enum';
import {SnackbarService} from '../../services/snackbar.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit, OnDestroy {
  public horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  public verticalPosition: MatSnackBarVerticalPosition = 'top';
  public isLoading = false;
  private loadingSub: Subscription;
  private successSub: Subscription;
  private errorSub: Subscription;
  private infoSub: Subscription;

  constructor(
    private snackbarService: SnackbarService,
    public router: Router,
    private snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.subscribeToData();
  }

  public ngOnDestroy(): void {
    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }
    if (this.successSub) {
      this.successSub.unsubscribe();
    }
    if (this.infoSub) {
      this.infoSub.unsubscribe();
    }
    if (this.errorSub) {
      this.errorSub.unsubscribe();
    }
  }

  private subscribeToData() {
    this.loadingSub = this.snackbarService.loading.subscribe(isLoading => {
      this.isLoading = isLoading;
    });

    this.infoSub = this.snackbarService.info.subscribe(message => {
      this.showMessage(message, SnackbarType.Info);
    });

    this.successSub = this.snackbarService.success.subscribe(message => {
      this.showMessage(message, SnackbarType.Success);
    });

    this.errorSub = this.snackbarService.error.subscribe(message => {
      this.showMessage(message, SnackbarType.Error);
    });
  }

  private showMessage(
    message: string,
    className: string,
    duration: number = 4000
  ): void {
    if (message && message.length > 0) {
      this.snackBar.open(message, '', {
        panelClass: className,
        duration: duration,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    }
  }
}
