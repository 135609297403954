import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {IAdministrator, IAdministratorBody, IAdministratorDetail} from "../models/administrator.model";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public administrators: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public administrators$: Observable<any> = this.administrators.asObservable();

  constructor(private http: HttpClient) {}

  public setAdministrators(administrators: IAdministrator[]): void {
    this.administrators.next(administrators);
  }

  public getAdministrators(): Observable<IAdministrator[]> {
    if (!this.administrators.value.length) {
        this.http.get(`${environment.baseUrl}/api/v1/admin/users`).pipe(
          tap((response) => {
            this.administrators.next(response);
          })
        ).subscribe();
    }
    return this.administrators.asObservable();
  }

  public changeAdminActive(administrator, isActive: boolean): Observable<any> {
    return this.http.put(
      `${environment.baseUrl}/api/v1/admin/users/user/status?userId=${administrator.id}&isActive=${isActive}`,
      null
    ).pipe(
      tap(() => {
        const administrators = this.administrators.getValue();
        const foundIndex = administrators.findIndex(x => x.id == administrator.id);
        administrators[foundIndex].active = !administrator.active;
        this.administrators.next(administrators);
      })
    );
  }

  public createAdmin(body: IAdministratorBody): Observable<IAdministrator> {
    return this.http
      .post(`${environment.baseUrl}/api/v1/admin/users`, body)
      .pipe(
        map((administrator: IAdministrator) => {
          if(administrator.roles && administrator.roles.length > 0) {
            administrator.role = administrator.roles[0];
          }
          this.administrators.next([...this.administrators.getValue(), administrator]);
          return administrator;
        })
      );
  }

  public editAdmin(adminId: string, body: IAdministratorDetail): Observable<IAdministrator> {
    return this.http.put<IAdministrator>(`${environment.baseUrl}/api/v1/admin/users?userId=${adminId}`, body);
  }


  public updateAdministratorsList(user): void {
    const administrators = this.administrators.getValue();
    if(administrators) {
      const index = administrators.findIndex(x => x.id === user.id );
      administrators[index] = user;
      this.administrators.next(administrators);
    }
  }

  public getUserById(adminId: string): Observable<IAdministrator> {
    return this.getAdministrators().pipe(
      map((administrators: IAdministrator[]) => {
        return administrators.find(admin => admin.id === adminId);
      })
    )
  }

  public getAdminSupports(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/api/v1/admin/supports`);
  }
}
