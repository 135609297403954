import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService  {
  private isLoading = new Subject<boolean>();
  private infoString = new Subject<string>();
  private successString = new Subject<string>();
  private errorString = new Subject<string>();

  constructor() {
    this.isLoading.next(false);
  }

  public info = this.infoString.asObservable();
  public success = this.successString.asObservable();
  public error = this.errorString.asObservable();
  public loading = this.isLoading.asObservable();

  public showInfo(message: string): void {
    this.infoString.next(message);
  }

  public showSuccess(message: string): void {
    this.successString.next(message);
  }

  public showError(message: string): void {
    this.errorString.next(message);
  }

  public showLoading(): void {
    this.isLoading.next(true);
  }

  public hideLoading(): void {
    this.isLoading.next(false);
  }
}
