import * as moment from 'moment';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DATE_FORMATS_TASK = {
  parse: {
    dateInput: 'YYYY-MM-DDTHH:mm:ss',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DATE_FORMATS_RECOMMENDED_ACTION = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: DATE_FORMATS_TASK.display
};



export const SERVER_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_ABSOLUTE_MAX = moment().format(SERVER_DATE_FORMAT);
export const DATE_ABSOLUTE_MIN = moment('01/01/1995', DATE_FORMATS.parse.dateInput).format(SERVER_DATE_FORMAT);
export const DATE_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const CURRENT_YEAR_MONTH_DAY = moment().set({hour: 0, minute: 0, second: 0}).format(SERVER_DATE_FORMAT);
