export enum UserRoles {
  EnterpriseSupport = 'ENTERPRISE_SUPPORT',
  EnterpriseAdministrator = 'ENTERPRISE_ADMINISTRATOR',
  TenantAdministrator = 'TENANT_ADMINISTRATOR',
  WorkflowManager = 'WORKFLOW_MANAGER',
  DataManager = 'DATA_MANAGER',
  UserManager = 'USER_MANAGER',
  UserReadOnly = 'USER_READ_ONLY',
  User = 'USER'
}
