import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse, HttpResponse,
} from '@angular/common/http';
import {MatDialog} from "@angular/material/dialog";
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {InfoService} from '../../info.service';
import {SecurityService} from "../../security/services/security.service";
import {ErrorCodeEnum as ErrorCode} from "../enums/error-code.enum";
import {AuthService} from '../services/auth.service';
import {SnackbarService} from "../services/snackbar.service";

@Injectable()
export class RequestInfoInterceptor implements HttpInterceptor {
  constructor(
    private infoService: InfoService,
    private router: Router,
    private authService: AuthService,
    private securityService: SecurityService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,

  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        // if (event instanceof HttpResponse) {
        //   if (!request.url.includes('/me') && !request.url.includes('/auth')) {
        //     this.infoService.getInfo().toPromise();
        //   }
        // }
        if (event instanceof HttpResponse) {
          if(this.authService.isLoggedIn() && !request.url.includes('/logout')) {
            if(this.securityService.securityConfig) {
              this.securityService.resetCount();
            }
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 410 || error.status === 401) {
          this.authService.isAdminModule(this.router.url) ? this.router.navigate(['/admin/signin']) :
          this.router.navigate(['/signin']);
          this.dialog.closeAll();
          this.authService.removeAuthorizedFlag();
        }
        if (error.error.code) {
          this.snackbarService.showError(ErrorCode[error.error.code])
        }
        return throwError(error);
      })
    );
  }
}
