export const DATA_SOURCES = {
  VAERS: {
    caption: 'VAERS',
    type: 'VAERS',
    color: '#ff7043',
    order: 0,
  },
  FAERS: {
    caption: 'FAERS',
    type: 'FAERS',
    color: '#ffb74d',
    order: 1,
  },
  ARGUS: {
    caption: 'Oracle Argus',
    type: 'ARGUS',
    color: '#00bcd4',
    order: 2,
  },
   IVP: {
    caption: 'IVP',
    type: 'IVP',
    color: '#00d49f',
    order: 3,
  }
};
