import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

import {IUser} from "../../../shared/interfaces/user.interfaces";

@Component({
  selector: 'app-drug-group-modal',
  templateUrl: './drug-group-modal.component.html',
  styleUrls: ['./drug-group-modal.component.scss']
})
export class DrugGroupModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: IUser) { }

  ngOnInit(): void {
  }

}
