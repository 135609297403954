export enum SessionType {
  All = 'ALL',
  Session = 'SESSION',
  FailedLogin = 'FAILED_LOGIN'
}

export enum SessionModalMode {
  ByIP = 'byIP',
  Detailed = 'detailed',
  Failed = 'failed',
  ByAdminIP = 'byAdminIP'
}
