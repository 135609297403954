import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {UserRoles} from '../enums/user-roles.enum';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  public checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    const isAdmin = this.authService.isAdmin();
    if (this.authService.isLoggedIn() && !isAdmin) {
      return true;
    } else {
      this.redirectToLoginPage();
      return false;
    }
  }

  private checkRole(route, isAdmin): boolean {
    const allowedRoles = route.data && route.data.allowedRoles && route.data.allowedRoles.admin;
    //todo: is temp solution, refactor it
    if (isAdmin && allowedRoles) {
      return true;
    } else if((!isAdmin && allowedRoles) || (isAdmin && !allowedRoles)) {
      return false;
    } else {
      return true;
    }
  }

  private redirectToLoginPage(): void {
    if (this.authService.isAdmin()) {
      this.router.navigate(['/admin/signin']);
    } else {
      this.router.navigate(['/signin']);
    }
  }



}
