import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {environment} from "../environments/environment";
import {SelectivePreloadingStrategyService} from "./selective-preloading-strategy.service";
import {AuthorizedGuard} from "./shared/guards/authorized.guard";
import {UnauthorizedGuard} from "./shared/guards/unauthorized.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: environment.defaultRoute,
    pathMatch: 'full'
  },
  {
    path: 'signal-pro',
    loadChildren: () => import('./signal-pro/signal-pro.module').then(m => m.SignalProModule),
    canActivate: [AuthorizedGuard],

  },
  {
    path: 'signin',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [UnauthorizedGuard],
    data: {
      preload: true
    },
  },
  {
    path: 'security',
    loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
    data: {
      preload: true
    },
  },
  {
    path: 'admin/security',
    loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
    data: {
      preload: true
    },
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    data: {
      preload: true,
    },
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: SelectivePreloadingStrategyService
  })],
  exports: [RouterModule],
  providers: [SelectivePreloadingStrategyService]
})
export class AppRoutingModule { }
