import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {SERVER_DATE_FORMAT} from "../../shared/constants/date.constant";
import {DEFAULT_FILTER_MODEL} from '../../shared/constants/default-filter.constant';
import * as _ from 'lodash';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {map, switchMapTo} from 'rxjs/operators';
import * as moment from 'moment';
import {ITemplate} from "../interfaces/template.interface";

import {FilterTemplateModel as FilterTemplate} from "../models/filter-template.model";
import {DATA_SOURCES} from "../../shared/constants/data-source.constant";
import {ITenantDataSource} from "../../info.service";

@Injectable({
  providedIn: 'root'
})


export class FilterService {
  public defaultFilterModel = DEFAULT_FILTER_MODEL;
  private filterTemplates$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public filterTemplates;
  public filterGroups: Array<string> = Object.keys(DATA_SOURCES);
  public formData;
  public defaultFilter = Object.assign({}, DEFAULT_FILTER_MODEL);
  public currentFilter$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentFilter = this.currentFilter$.asObservable();

  public tenantDataSources$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public tenantDataSources = this.tenantDataSources$.asObservable();

  public excludedSignals$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public excludedSignals = this.excludedSignals$.asObservable();

  public onUpdatePotentialSignalTable = new Subject();

  constructor(
    private http: HttpClient,
  ) {
  }

  private getActualSources(): Array<string> {
    let sources = [];
    this.filterGroups.forEach((source) => {
      this.tenantDataSources$.value.forEach((tenantSource: ITenantDataSource) => {
        if (tenantSource.type === source) {
          sources.push(source)
        }
      })
    });
    return sources
  }

  public getFilter() {
    return this.currentFilter;
  }

  public getTenantDataSources() {
    return this.tenantDataSources;
  }

  public setFilter(filter: any) {
    this.currentFilter$.next(filter)
  }

  public setTenantDataSources(sources: any) {
    this.tenantDataSources$.next(sources)
  }

  public getFilterTemplateList(): Observable<ITemplate[]> {
    return this.http
      .get(`${environment.baseUrl}/api/v1/filters/templates/`)
      .pipe(
        map((templates: ITemplate[]) => {
          this.filterTemplates = templates;
          this.extractFilterTemplates();
          return templates;
        })
      )
      .pipe(
        switchMapTo(this.filterTemplates$.asObservable())
      );
  }


  public getFilterTemplate(filterTemplateId): Observable<any> {
    if (filterTemplateId) {
      return this.http.get(
        `${environment.baseUrl}/api/v1/filters/templates/template?templateId=${filterTemplateId} `
      );
    } else {
      return of(this.defaultFilterModel);
    }
  }

  public addFilterTemplate(filter): Observable<any> {
    return this.http
      .post(`${environment.baseUrl}/api/v1/filters/templates`, filter)
      .pipe(
        map(response => {
          this.filterTemplates$.next([...this.filterTemplates$.value, response]);
        })
      );
  }

  public updateFilterTemplate(filter): Observable<any> {
    const filterTemplates = this.filterTemplates;
    const index = filterTemplates.findIndex(x => x.id === filter.id);
    filterTemplates[index] = filter;
    this.filterTemplates$.next(filterTemplates);
    return this.http.put(
      `${environment.baseUrl}/api/v1/filters/templates/template?templateId=${filter.id}`,
      filter
    );
  }

  public deleteFilterTemplate(filterId): Observable<any> {
    const filterTemplates = this.filterTemplates$.value;
    const filteredTemplates = filterTemplates.filter(x => x.id !== filterId);
    this.filterTemplates$.next(filteredTemplates);
    return this.http.delete(
      `${environment.baseUrl}/api/v1/filters/templates/template?templateId=${filterId}`
    );
  }

  public updateFilterTemplateShareStatuses(templates: ITemplate[]): Observable<void> {
    return this.http.put<void>(
      `${environment.baseUrl}/api/v1/filters/templates/share`,
      templates
    );
  }

  public saveOrgToolManagementSettings(toolManagementSettings): Observable<any> {
    return this.http.put(`${environment.baseUrl}/api/v1/tenants/settings`, toolManagementSettings);
  }

  public prepareDataForSendingRequest(formData): any {
    const filterModel = {
      fromDate: formData.fromDate,
      toDate: formData.toDate,
      subFilters: formData.subFilters
    };

    this.formData = formData;
    return filterModel;
  }

  public applyFilterTemplate(filter): Observable<any> {
    return this.http.post(`${environment.baseUrl}/api/v1/filters`, filter);
  }

  public converteModifiedFilterForm(formData): any {
    let filterData = JSON.parse(JSON.stringify(formData));
    delete filterData.name;
    // fix if it's template
    if (formData.owner) {
      filterData = formData.filter;
    }
    this.getActualSources().map(group => {
      if (_.isEmpty(filterData.subFilters)) {
        const obj = {};
        obj[group] = {};
        _.defaults(filterData.subFilters, obj);
        Object.keys(DEFAULT_FILTER_MODEL.subFilters[group]).map((filterField) => {
          const objField = {};
          objField[filterField] = [];
          _.defaults(filterData.subFilters[group], objField);
          if (DEFAULT_FILTER_MODEL.subFilters[group]) {
            if (filterField === 'type') {
              filterData.subFilters[group][filterField] = DEFAULT_FILTER_MODEL.subFilters[group][filterField];
            } else {
              filterData.subFilters[group][filterField] = [];
            }
          }
        });
      } else {
        if (filterData.subFilters[group]) {
          Object.keys(filterData.subFilters[group]).map((field, i) => {
            if (field === 'type') {
              filterData.subFilters[group][field] = formData.subFilters[group][field];
            } else {
              filterData.subFilters[group][field] = [];
              filterData.subFilters[group][field] = formData.subFilters[group][field] ?
                formData.subFilters[group][field].map(item => item.name ? item.name : item) : [];
              filterData.subFilters[group][field] = this.sortValueBasedOnOriginal(filterData, group, field);
            }
          });
        } else {
          const obj = {};
          obj[group] = {};
          _.defaults(filterData.subFilters, obj);
          Object.keys(DEFAULT_FILTER_MODEL.subFilters[group]).map((filterField) => {
            const objField = {};
            objField[filterField] = [];
            _.defaults(filterData.subFilters[group], objField);
            if (DEFAULT_FILTER_MODEL.subFilters[group]) {
              if (filterField === 'type') {
                filterData.subFilters[group][filterField] = DEFAULT_FILTER_MODEL.subFilters[group][filterField];
              } else {
                filterData.subFilters[group][filterField] = [];
              }
            }
          });
        }
      }

      filterData.fromDate = this.initFilterDate(formData.fromDate);
      filterData.toDate = this.initFilterDate(formData.toDate);
    });
    return filterData;
  }

  public refreshPotentialSignalTable(): void {
    this.onUpdatePotentialSignalTable.next();
  }

  private sortValueBasedOnOriginal(filterData, group, field): any {
    return this.defaultFilter.subFilters[group][field].map((item) => {
      if (filterData.subFilters[group][field].includes(item.name)) {
        return item.name;
      }
    }).filter((item) => item);
  }

  private initFilterDate(date): string {
    if (moment(date).isValid()) {
      return moment(date).format(SERVER_DATE_FORMAT);
    }
  }

  private mergeWithDefaultFilter(filterGroup, key): void {
    this.formData[filterGroup][key] = {
      ...this.defaultFilter[filterGroup][key],
      ...this.formData[filterGroup][key]
    };
  }

  private formatPristineFilterControls(groupControl): [] {
    const group = Object.keys(groupControl).map(key => ({'key': key, 'value': groupControl[key]}));
    const filteredGroup = (<any>group).filter(item => item.value);
    return filteredGroup;
  }

  private isPristineFilterControl(groupControl): boolean {
    return !Array.isArray(groupControl);
  }

  private extractFilterTemplates(): FilterTemplate[] {
    const filterTemplates = this.filterTemplates.map((template) => {
      return template;
    });
    this.filterTemplates$.next(filterTemplates);
    return filterTemplates;
  }
}
