import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';

import {SecurityService} from './security/services/security.service';
import {ConfirmModalComponent} from './shared/components/confirm-modal/confirm-modal.component';
import {
  AuthService,
  DEFAULT_HOME_PAGE_ROUTE,
  StorageKeys,
} from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private idleTimeout;
  private sub: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private securityService: SecurityService
  ) {}

  public ngOnInit(): void {
    this.initSecurityConfig();
    this.authService.userActivity.next();
    this.sub.add(
      this.securityService.sessionWarning.subscribe(() => {
        this.showConfirmSessionModal();
      })
    );
    this.sub.add(
      this.securityService.userInactive
        .pipe(
          switchMap(() => {
            return this.authService.signout();
          })
        )
        .subscribe(() => {
          this.router.navigate(['/']);
        })
    );
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  @HostListener('window:storage', ['$event'])
  onStorage(event: StorageEvent): void {
    if (
      event.storageArea === localStorage &&
      event.key === StorageKeys.isAuthorized
    ) {
      this.router.navigate([
        `${this.authService.isLoggedIn() ? DEFAULT_HOME_PAGE_ROUTE : '/'}`,
      ]);
    }
  }

  public showConfirmSessionModal(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '550px',
      data: {
        title: `Your session is going to end soon, would you like to continue?`,
        okText: 'Yes ',
        cancelText: 'No',
        isConfirmed: true,
      },
    });
    this.sub.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.securityService.resetCount();
          this.securityService.getAppVersion().toPromise();
        }
      })
    );
  }

  public initSecurityConfig(): void {
    this.sub.add(
      this.authService.userActivity
        .pipe(
          switchMap(() => {
            return this.securityService.getSecurityConfig();
          }),
          tap((config) => {
            this.idleTimeout = config.idleTimeout;
          })
        )
        .subscribe(() => {
          if (this.authService.isLoggedIn() && this.idleTimeout) {
            this.securityService.initSession(this.idleTimeout);
          }
        })
    );
  }
}
